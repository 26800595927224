<template>
  <div>
    <div v-if="!items.length">
      Нет данных
    </div>
    <VTimeline
        v-else
        dense
        clipped
    >
      <VTimelineItem
          v-for="(item, index) in items"
          :key="item.id"
          :color="index > 0 ? 'grey' : 'primary'"
          class="mb-4"
          small
      >
          <strong>{{ item.author }}</strong>
          <small v-if="!!item.role.length"> ({{ item.role }})</small>
          <VChip
                  class="white--text ml-2 mr-1"
                  :color="getApprovalColor(item.code)"
                  label
                  small
          >
              {{ item.status }}
          </VChip>
          <div><small>{{ item.date }}</small></div>
          <div v-if="item.publicComment">
              <i>Комментарий:</i>
              <div v-html="item.publicComment" />
          </div>
      </VTimelineItem>
    </VTimeline>
  </div>
</template>

<script>

import { REVIEWER_TASK_STATUSES } from '@/store/spa/enums';

export default {
  name: 'TimeLine',
  props: {
    items: { type: Array, default: () => [] }
  },
  methods: {
    getApprovalColor(statusCode) {
      if (statusCode === REVIEWER_TASK_STATUSES.NOT_CHECKED.value) return 'info';
      if (statusCode === REVIEWER_TASK_STATUSES.REJECTED.value) return 'error';
      if (statusCode === REVIEWER_TASK_STATUSES.APPROVED.value) return 'success';
      return 'grey';
    },
  }
}
</script>

<style scoped>

</style>
