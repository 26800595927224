<template>
    <div :class="$style.root">
        <VBtn v-if="isOnApproving || !isApproved" color="success" class="mr-2" depressed :small="small" :disabled="pending" @click.stop="onApprove">Согласовать</VBtn>
        <VBtn v-if="isOnApproving || !isRejected" color="error" class="mr-2" depressed :small="small" :disabled="pending" @click.stop="onReject">Отклонить</VBtn>

        <VDialog v-model="dialog" max-width="900" scrollable persistent>
            <VCard tile>
                <VToolbar flat dark color="primary">
                    <VToolbarTitle class="px-2">Решение по заявке</VToolbarTitle>
                    <VSpacer/>
                    <VBtn icon dark @click="onDialogClose">
                        <VIcon>mdi-close</VIcon>
                    </VBtn>
                </VToolbar>
                <VCardText class="pt-5">
                    <VCardText>
                        <DecisionForm :disabled="pending" @submit="onSubmitDecision" />
                    </VCardText>
                </VCardText>
            </VCard>
        </VDialog>
    </div>
</template>

<script>
import {get} from 'lodash-es';
import {APPLICATION_ACTIONS, APPLICATION_STATUSES, ROLES} from '@/store/spa/enums';
import DecisionForm from '@/components/spa/ApplicationDetail/components/DecisionForm.vue';

export default {
    name: 'ApprovingButtons',
    components: {DecisionForm},
    props: {
        id: { type: String, required: true },
        state: {type: String, required: true},
        pending: {type: Boolean, default: false},
        small: { type: Boolean, default: false },
    },
    data() {
        return {
            dialog: false,
            decision: null,
        }
    },
    computed: {
        isDraft() {
            return get(APPLICATION_STATUSES, 'DRAFT.value') === this.state;
        },
        isOnApproving() {
            return get(APPLICATION_STATUSES, 'ON_APPROVING.value') === this.state;
        },
        isApproved() {
            return get(APPLICATION_STATUSES, 'APPROVED.value') === this.state;
        },
        isRejected() {
            return get(APPLICATION_STATUSES, 'REJECTED.value') === this.state;
        },
        isReviewer() {
            return this.hasRole(ROLES.REVIEWER, 'spa');
        },
    },
    methods: {
        onApprove: function() {
            const decision = get(APPLICATION_ACTIONS, 'TO_APPROVE.value', 'to_approve');
            const id = this.id;
            this.$emit('submit-decision', { id, decision, comment: '' });
        },
        onReject: function() {
            this.dialog = true;
            this.decision = get(APPLICATION_ACTIONS, 'TO_REJECT.value', 'to_reject');
        },
        onSubmitDecision: function (comment) {
            const decision = this.decision;
            const id = this.id;
            this.$emit('submit-decision', { id, decision, comment });
            this.onDialogClose();
        },
        onDialogClose() {
            this.dialog = false;
            this.decision = null;
        },
    },
}
</script>

<style module lang="scss">
.root {
    padding: 10px;
    display: flex;
}
</style>
