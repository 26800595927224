<template>
<div :class="$style.root">
  <VForm v-model="valid" lazy-validation  ref="form" @submit.prevent="onSave">
    <SectionCard label="Информация для заявки на доступ в ЛК Поставщика">
      <div :class="$style.item">
        <template v-for="code in ['codeMdg', 'contractNumber']">
          <ApplicationValues
              :class="getClassByValue(code)"
              :code="code"
              :application="id"
              :label="fields[code]['label']"
              :readonly="fields[code]['readonly']"
              :values="fields[code]['values']"
              :rules="code !== 'hasApprovingForPersonalInfo' ? rules.required : []"
              :hint="fields[code]['hint']"
              :key="code"
              @update:values="(values) => fields[code]['values'] = values"
          />
        </template>
      </div>
    </SectionCard>
  </VForm>
  <VFooter app>
    <VProgressLinear v-if="pending" absolute indeterminate color="primary darken-2" :class="$style.progress" />
    <div class="py-3 grow">
        <div class="float-left">
          <VBtn v-if="isReviewer" class="mr-6" outlined depressed @click="$router.push({ name: 'spa/ApplicationListView' })">
              <VIcon>mdi-arrow-left</VIcon>
              Назад к списку
          </VBtn>
        </div>
        <div class="float-right">
          <template>
              <template v-if="diff && !isReviewer">
                  <VBtn color="primary" class="mr-2" depressed :disabled="pending" @click="onSave">Сохранить</VBtn>
                  <VBtn color="secondary" class="mr-2" depressed :disabled="pending" @click="onReset">Сбросить</VBtn>
              </template>
              <template v-else>
                  <template v-if="(isDraft || isRejected) && !isReviewer">
                      <VBtn v-if="!diff" color="primary" class="mr-2" depressed :disabled="pending || !valid" @click="onToApprove">Отправить на согласование</VBtn>
                  </template>
                  <template v-if="isReviewer">
                      <ApprovingButtons :state="state" :pending="pending" :id="id" @submit-decision="onSubmitDecision" />
                  </template>
              </template>
          </template>
        </div>
    </div>
  </VFooter>
</div>
</template>

<script>
import { isArray, isEmpty, get } from 'lodash-es';
import { mapActions, mapGetters } from 'vuex';
import SectionCard from '@/components/spa/SectionCard/SectionCard';
import ApplicationValues from '@/components/spa/ApplicationDetail/components/ApplicationValues';
import { APPLICATION_STATUSES, ROLES, APPLICATION_ACTIONS } from '@/store/spa/enums';
import ApprovingButtons from '@/components/spa/ApprovingButtons/ApprovingButtons.vue';

export default {
  name: 'ApplicationDetail',
  components: {
    ApprovingButtons,
    SectionCard,
    ApplicationValues,
  },
  props: {
    id: { type: String, required: true },
    state: { type: String, required: true },
    count: { type: Number },
    values: { type: Object },
    info: { type: Object }
  },
  data() {
    return {
      timer: null,
      inspectors: [],
      valid: true,
      fields: JSON.parse(JSON.stringify(this.values)),
      rules: {
        required: [
          v => {
            if (isArray(v) && isEmpty(v)) return 'Обязательное поле';
            if (!v) return 'Обязательное поле';
            return true;
          }
        ]
      },
    }
  },
  computed: {
    ...mapGetters({
      pending: 'spa/pending',
      hasRole: 'user/hasRole',
      user: 'user/current',
    }),
    isDraft() {
      return get(APPLICATION_STATUSES, 'DRAFT.value') === this.state;
    },
    isOnApproving() {
      return get(APPLICATION_STATUSES, 'ON_APPROVING.value') === this.state;
    },
    isApproved() {
      return get(APPLICATION_STATUSES, 'APPROVED.value') === this.state;
    },
    isRejected() {
      return get(APPLICATION_STATUSES, 'REJECTED.value') === this.state;
    },
    isReviewer() {
      return this.hasRole(ROLES.REVIEWER, 'spa');
    },
    diff() {
      return JSON.stringify(this.values) !== JSON.stringify(this.fields);
    },
  },
  methods: {
    ...mapActions({
      updateApplication: 'spa/updateApplication',
      approveApplication: 'spa/approveActionApplication',
      fetchApplicationDetail: 'spa/fetchApplicationDetail',
    }),
    // eslint-disable-next-line no-unused-vars
    getClassByValue(key) {
      return '';
    },
    onSubmitDecision({ decision, comment }) {
      const id = this.id;
      this.approveApplication({ id, action: decision, comment }).then((result) => {
        if (result) {
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: 'Решение вынесено',
          });
          return this.fetchApplicationDetail({ id });
        }
      });
    },
    onToApprove: function() {
      const id = this.id;
      const action = get(APPLICATION_ACTIONS, 'TO_REVIEWER_APPROVE.value', 'to_reviewer_approve');
      if (this.$refs.form.validate()) {
        this.approveApplication({ id, action }).then((result) => {
          if (result) {
            this.$notify({
              type: 'success',
              title: 'Успех',
              text: 'Заявка успешно отправлена на согласование',
            });
            return this.fetchApplicationDetail({ id: '' });
          }
        });
      }
    },
    onSave: function() {
      const id = this.id;
      const fields = this.fields;
      this.updateApplication({ id, fields }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Заявка успешно сохранена',
        });
      });
    },
    onReset: function() {
      this.fields = JSON.parse(JSON.stringify(this.values));
    },
  },
  watch: {
    diff: {
      immediate: true,
      handler: function(value) {
        if (value) {
          this.timer = setInterval(this.onSave, 5 * 60 * 1000) // 5 мин
        } else {
          clearInterval(this.timer);
        }
        this.$route.meta.beforeEach = (to, from, next) => {
          if (from.name === 'spa/CounterpartyDetailView' && this.diff) {
            this.$notify({
              type: 'warning',
              title: 'Подождите',
              text: 'Идет сохранение',
            });
            this.onSave();
          } else {
            clearInterval(this.timer);
            next();
          }
        };
      }
    },
    values: {
      handler: function() {
        this.onReset();
      }
    },
  }
}
</script>

<style module lang="scss">
.root {

}
.item {
  padding: 20px 16px;
  margin: 0 -16px;
  & + & {
    border-top: 1px dashed rgba(0, 0, 0, 0.1);
  }
}
.value {
  padding: 10px 16px;
  margin: 0 -16px;
}
.title {
  font-weight: bold;
  font-size: 14px;
  color: #212121;
}
.progress {
  top: 0;
}
.product {
  padding: 10px 16px;
  margin: 0 -16px;
  .left {
    float: left;
    padding-right: 10px;
    .index {
      font-weight: bold;
      padding: 9px 0;
    }
  }
  .right { float: right }
  &:first-child .right { margin-top: 22px; }
}
.control {
  float: right;
  min-height: 40px;
  margin-left: 10px;
  position: relative;
  z-index: 1;
}
.label {
  pointer-events: none;
}
</style>
